<template>
  <div class="app flex-row align-items-center">
    <div class="container mt-3">
      <b-row class="row justify-content-center">
        <b-col md="6" sm="0" class="left mt-5 display-mobile">
          <img
            style="height: 75%"
            src="../../assets/img/design.png"
            alt="admin@bootstrapmaster.com"
          />
        </b-col>
        <b-col md="6" class="right">
          <b-card-group>
            <b-card-body class="background-mobile">
              <b-row align-v="center">
                <b-col class="text-center">
                  <img
                    src="../../assets/img/gramedia_digital.png"
                    alt="admin@bootstrapmaster.com"
                    class="m-5"
                  />
                </b-col>
              </b-row>
              <b-form @submit.prevent="actionLogin">
                <h1 class="h2 text-center">Digital Books Gramedia</h1>
                <b-form-group label="Email Address" class="mt-5 mb-5">
                  <b-form-input
                    type="text"
                    class="form-control"
                    placeholder="email"
                    autocomplete="email"
                    v-model="Form.email"
                    required
                  />
                </b-form-group>
                <b-form-group label="Password" class="mt-5 mb-0">
                  <div style="position: relative">
                    <b-form-input
                      v-if="showPassword"
                      type="text"
                      class="form-control"
                      placeholder="Password"
                      v-model="Form.password"
                      required
                    />
                    <b-form-input
                      v-else
                      type="password"
                      class="form-control"
                      placeholder="Password"
                      v-model="Form.password"
                      required
                    />
                    <span class="icon-eye" @click="toggleShow">
                      <b-icon :icon="icon"></b-icon>
                    </span>
                  </div>
                </b-form-group>
                <b-row>
                  <b-col>
                    <router-link :to="{ path: '/forgotPassword' }">
                      <b-button variant="link" class="px-0 mb-5"
                        >Forgot password?
                      </b-button>
                    </router-link>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button
                      :disabled="isDisable"
                      lg="4"
                      type="submit"
                      variant="primary"
                      class="px-4 w-100"
                    >
                      Login
                      <i class="fa fa-sign-in" aria-hidden="true"></i>
                      <b-spinner
                        v-if="!isDisable && isLoading"
                        small
                        type="grow"
                        label="Spinning"
                      ></b-spinner>
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Login',
  data() {
    return {
      Form: {},
      isActive: false,
      showPassword: false,      
    };
  },
  computed: {
    ...mapState({
      success: (state) => state.login.isSuccess,
      message: (state) => state.login.successMessage,
      isLoading: (state) => state.login.isLoading,
      token: (state) => state.login.token,
      isError: (state) => state.login.isError,
    }),
    isDisable() {
      return !(
        this.Form.email &&
        this.Form.email.length > 4 &&
        this.Form.password &&
        this.Form.password.length > 4
      );
    },
    icon(){
      return this.showPassword ? 'eye' : 'eye-slash';
    }
  },
  watch: {    
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Login | Content Management System Ebooks Gramedia.com';
      },
    },
    isError: function() {
      if (this.isError) {
        if (this.message) {
          this.showAlert({
            success: false,
            message: 'Error Login, ' + this.message,
            title: 'Error Login',
          });
        } else {
          this.showAlert({
            success: false,
            message: 'Error Login',
            title: 'Error Login',
          });
        }
      }
    },
  },
  methods: {
    ...mapActions('login', ['loginAction']),
    ...mapActions('auth', ['setLogin', 'setAuth']),

    actionLogin() {
      let email =  this.Form.email.trim();
      let payload = {
        email,
        password: this.Form.password,
      };
      let message = 'Error Authentication Login';
      this.loginAction(payload)
        .then((response) => {
          if(response.message) {
            message = response.message;
          }
          const result = response.result;
          const resetpassword = response.resetpassword;
          const modeTwoFactor = response.twoFactor;
          const token = response.token; //this jwt if result same password and two factor, if not then token login
          if (resetpassword) {
            this.$router.push({
              path: `/change-password/${token}`,
            });
          } else if (modeTwoFactor) {
            if (result) {
              this.$router.push({
                path: `/twoFactor/${token}`,
              });
            } else {
              this.showAlert({
                success: false,
                message: message,
                title: 'Error',
              });
            }
          } else {
            if (result) {
              localStorage.setItem('session_name', response.username);
              localStorage.setItem('session_token', token);
              localStorage.setItem('refresh_token', response.refresh_token);
              localStorage.setItem('session_auth', JSON.stringify(response.data));
              this.setAuth(response.data);
              this.setLogin({ isLogin: true });
              this.showAlert({
                success: true,
                message: message,
              });
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else {
              this.showAlert({
                success: false,
                message: message,
                title: 'Error',
              });
            }
          }
        })
        .catch(() => {
          this.showAlert({
            success: false,
            message: 'Login Failed verification: ' + message,
            title: 'Error',
          });
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
        });
      },
    showAlert(data) {
      this.$swal({
        toast: 'true',
        position: 'top-end',
        icon: data.success ? 'success' : 'error',
        title: data.message,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },
    toggleShow() {
      this.showPassword = !this.showPassword;

    },
  },
  mounted() {
    let token = localStorage.getItem('session_token');
    if (token) {
      this.$router.push({ path: '/' });
    }
  },
};
</script>
<style lang="css" scoped>
.center {
  width: 500px;
  height: 400px;
  top: 20%;
  position: absolute;
  top: 20%;
  margin-left: auto;
  margin-right: auto;
}
.form-control {
  border: 0;
  border-bottom: 1px solid #000;
  outline: none;
}
input.form-control:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
@media (max-width: 768px) {
  .background-mobile {
    background-color: #fff;
  }
  .display-mobile {
    display: none;
  }
}
.icon-eye { 
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  padding-right: 1px;
  cursor: pointer;
}
</style>
